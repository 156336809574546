import log from './logger';
import sha256 from './sha256';

const apiAddExternalId = (id, type, pqid, hash) => {
  if (hash) {
    id = sha256(id);
  }
  window.cX.callQueue.push([
    'invoke',
    () => {
      const apiUrl = `https://api.cxense.com/profile/user/external/link/update?callback={{callback}}&persisted=${encodeURIComponent(
        pqid
      )}&json=${encodeURIComponent(
        window.cX.JSON.stringify({
          id,
          type,
          cxid: window.cX.getUserId(),
        })
      )}`;
      window.cX.jsonpRequest(apiUrl);
      log(`perform profile/user/external/link/update with external id ${id}`);
    },
  ]);
};

export default apiAddExternalId;
